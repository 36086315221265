import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Navigation from './navgation'

const Header: React.FC = () => {
  const [navBar, setNavbar] = React.useState(false)

  React.useEffect(() => {
    const changeBackground = () => setNavbar(window.scrollY >= 80)
    changeBackground()
    window.addEventListener('scroll', changeBackground)
    return () => window.removeEventListener('scroll', changeBackground)
  }, [setNavbar])

  return (
    <header
      className={`fixed top-0 left-0 right-0 font-bc z-10 transition-colors duration-500 
      ${navBar ? 'bg-[#FFFFFFBB]' : 'bg-white'}
    `}
    >
      <div className="container mx-auto">
        <div className="flex justify-between items-center max-w-7xl mx-auto pt-8 pb-4 px-4 <sm:pt-4 <sm:pb-4">
          <div className="cursor-pointer <sm:w-40">
            <StaticImage
              src="../../../sources/images/mainlogo.png"
              alt="NutritionCourt Logo"
              placeholder="none"
              width={200}
            />
          </div>
          <Navigation />
        </div>
      </div>
    </header>
  )
}

export default Header
