import * as React from 'react'

const Navigation: React.FC = () => {
  const menu = [
    {
      name: '기술력',
      href: '#tech',
    },
    {
      name: '파트너',
      href: '#partners',
    },
    {
      name: '미션',
      href: '#mission',
    },
    {
      name: '서비스',
      href: '#program',
    },
    {
      name: '연혁',
      href: '#history',
    },
    {
      name: 'NC피플',
      href: '#people',
    },
    {
      name: 'App 웰코치',
      className: 'md:hidden text-wcgreen',
      href: 'https://www.welcoach.com',
    },
  ]

  const [isopen, setIsOpen] = React.useState<boolean>(false)

  const handleLinkClick = (href: string) => (e: React.MouseEvent) => {
    if (href.startsWith('#')) {
      e.preventDefault()
      document.querySelector(href)?.scrollIntoView({ behavior: 'smooth' })
      setIsOpen(false)
    }
  }

  return (
    <nav>
      <div className="relative p-2 z-10 md:hidden">
        <label htmlFor="check" className="nav">
          <input
            type="checkbox"
            id="check"
            onChange={() => setIsOpen((prev) => !prev)}
            checked={isopen}
          />
          <div />
          <div />
          <div />
        </label>
      </div>
      <div
        className={`z-5 flex items-center font-paybooc font-semibold transition-opacity duration-400 <md:fixed <md:inset-0 <md:pt-20 <md:flex-col <md:text-lg <md:bg-white ${
          isopen ? '<md:opacity-100' : '<md:pointer-events-none <md:opacity-0'
        }`}
      >
        {menu.map((data, idx) => (
          <a
            key={idx}
            href={data.href}
            className={
              data.className +
              ' mx-2 px-2 cursor-pointer <md:my-2 <md:py-1 hover:text-gray-400 transition-colors duration-300'
            }
            onClick={handleLinkClick(data.href)}
          >
            {data.name}
          </a>
        ))}
      </div>
    </nav>
  )
}

export default Navigation
