import * as React from 'react'
import { type PageProps } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import MainPage from './main'
import ogImg from '../sources/images/nclogo.png'

const IndexPage: React.FC<PageProps> = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'ko' }}>
        <meta charSet="utf-8" />
        <title>HOME | 뉴트리션코트</title>
        <meta
          name="description"
          content="사람 중심의 웰니스 건강관리 전문 기업"
        />
        <meta property="og:title" content="뉴트리션코트" />
        <meta property="og:image" content={ogImg} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="600" />
        <meta property="og:site_name" content="뉴트리션코트" />

        <meta name="twitter:card" content="photo" />
        <meta name="twitter:title" content="뉴트리션코트" />
        <meta name="twitter:image" content={ogImg} />
        <link rel="nc_logo" href="../sources/images/nclogo.png" />
      </Helmet>
      <Layout>
        <MainPage />
      </Layout>
    </>
  )
}

export default IndexPage
